import React, { useContext, useMemo, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { useHoverIntent } from 'react-use-hoverintent'

import MdxSuiteContext from '../../contexts/mdx-suite'
import { findActiveTrail } from '../../helpers/menu'

import MenuUl from '../menu/menu-ul'
import MenuItem from '../menu/menu-item'
import { Popper } from '../popper'
import useIsClient from '../../hooks/isClient'

type MenuPopperProps = {
  rootMenuItemId: string
  offset: string
}

export default function MenuPopper({
  rootMenuItemId,
  offset,
}: MenuPopperProps) {
  const queryResult = useStaticQuery(graphql`
    query MenuPopperQuery {
      allContentfulContentTypeMenuItem {
        nodes {
          ...MdxSuiteMenuItem
          subitems {
            ...MdxSuiteMenuItem
            subitems {
              ...MdxSuiteMenuItem
              subitems {
                ...MdxSuiteMenuItem
              }
            }
          }
        }
      }
    }
  `)

  const {
    pageContext: { pageId, locale: activeLocale, pageType },
    themeConfig: { pageTypeParentMenuItemIds },
  } = useContext(MdxSuiteContext)

  const menuRoot = queryResult.allContentfulContentTypeMenuItem.nodes.find(
    ({ sys: { locale, menuItemId } }) =>
      menuItemId === rootMenuItemId &&
      (!activeLocale || locale === activeLocale)
  )

  if (!menuRoot) {
    throw new Error(`Unable to locate root menu item ${rootMenuItemId}`)
  }

  const activeTrailId = useMemo(() => {
    const customPageId =
      pageTypeParentMenuItemIds && pageTypeParentMenuItemIds[pageType]
    return customPageId ?? pageId
  }, [pageTypeParentMenuItemIds, pageType, pageId])

  const activeTrail = useMemo(
    () =>
      findActiveTrail({
        id: activeTrailId,
        subTree: [menuRoot],
      }),
    [activeTrailId, menuRoot]
  )

  return (
    <PopperMenu
      children={menuRoot.subitems}
      activeTrail={activeTrail}
      offset={offset}
    />
  )
}

const PopperSubMenu = ({ activeTrail, offset, ...subMenuItemData }) => {
  const { isClient } = useIsClient()

  const [isHoveringItem, refHoverItem] = useHoverIntent({
    timeout: 300,
  })

  const referenceElement = useRef(null)

  return (
    <MenuItem
      {...subMenuItemData}
      key={subMenuItemData.sys.menuItemId}
      activeTrail={activeTrail}
      className="depth-0"
      ref={refHoverItem}
    >
      <div ref={referenceElement}>
        {isClient && subMenuItemData.subitems && isHoveringItem && (
          <Popper referenceElement={referenceElement.current} offset={offset}>
            <MenuUl className="depth-1">
              {subMenuItemData.subitems.map((menuItemData) => (
                <MenuItem
                  key={menuItemData.sys.menuItemId}
                  activeTrail={activeTrail}
                  className="depth-1"
                  {...menuItemData}
                />
              ))}
            </MenuUl>
          </Popper>
        )}
      </div>
    </MenuItem>
  )
}

type MenuItems = { sys: { menuItemId: string } }

type PopperMenuProps = {
  children: MenuItems[]
  activeTrail: MenuItems[]
  offset: string
}

function PopperMenu({ activeTrail, children, offset }: PopperMenuProps) {
  return (
    <MenuUl className="depth-0">
      {children.map(({ ...itemData }) => (
        <PopperSubMenu
          {...itemData}
          offset={offset}
          activeTrail={activeTrail}
          key={itemData.sys.menuItemId}
        />
      ))}
    </MenuUl>
  )
}
