import React from 'react'

import Icon from '../../icon'

interface MdxIconProps {
  /** Name of the icon to render. The available icons can be found in the <Link to="/docs/style-guide/#icons">theme documentation for icons</Link> */
  icon: string
  /** Color of the icon. Will use current text color by default.*/
  color?: string
  /** Adjust the vertical align of the icon. Useful values: top, bottom, middle, baseline, text-top, text-bottom */
  verticalAlign?: string
  svgProps?: any
}

/**
 * Renders an icon. The available icons can be found in the <Link to="/docs/style-guide/#icons">theme documentation for icons</Link>
 *
 * @example
 * <Icon icon="star" />
 * <Icon icon="instagram" />
 * <Icon icon="facebook" color="#4267B2" />
 * <Icon icon="youtube" color="red" />
 */
const MdxIcon = ({
  icon,
  color,
  verticalAlign = 'middle',
  svgProps,
}: MdxIconProps) => (
  <Icon
    icon={icon}
    color={color}
    verticalAlign={verticalAlign}
    svgProps={svgProps}
  />
)

export default MdxIcon
