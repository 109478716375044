import React, { useContext } from 'react'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'

import MdxSuiteContext from '../../../contexts/mdx-suite'
import { generatePageMap, getPageWithFallback } from '../../../helpers/routing'
import {
  useFormatSupportLineBreaks,
  formatSupportLineBreaks,
} from '../../../hooks/formatText'

import { langs, defaultLocale } from '../../../../page-config'

type LinkProps = {
  className?: string
  id?: string
  to?: string
  href?: string
  hash?: string
  title?: string
  openInNewTab?: boolean
  children?: React.ReactElement | React.ReactNode
  target?: string
  rel?: string
  enforceLocale?: boolean
}

/**
 * Link either an internal or external page.
 *
 * Title will be automatically set for internal pages
 *
 * @example
 * <Link id="randomPageId" />
 * @example
 * <Link id="randomPageId">Internal link with given title</Link>
 * @example
 * <Link to="/docs">Internal link to hardcoded page</Link>
 * @example
 * <Link href="https://google.com">External link to Google</Link>
 * @example
 * <Link href="https://google.com" openInNewTab>External link to Google, opening in a new tab</Link>
 */
export default function Link({
  children,
  hash,
  href,
  id,
  openInNewTab = false,
  title,
  to,
  className,
  enforceLocale,
  ...linkProps
}: LinkProps) {
  const {
    pageContext: { locale },
  } = useContext(MdxSuiteContext)

  if (openInNewTab) {
    linkProps.target = '_blank'
    linkProps.rel = 'noopener'
  }

  const formattedTitle = useFormatSupportLineBreaks(title)

  const result = useStaticQuery(graphql`
    query LinkQuery {
      allSitePage {
        nodes {
          ...MdxSuiteSitePageMetadata
        }
      }
    }
  `)

  // Link internal page when to is given
  if (to) {
    to = [to, hash ? `#${hash}` : null].filter(Boolean).join('')
    return (
      <GatsbyLink
        className={className}
        activeClassName="active"
        to={to}
        {...linkProps}
      >
        {children || formattedTitle}
      </GatsbyLink>
    )
  }

  // Render a normal anchor when a href is given
  if (href) {
    let cleanHref = href
    const domainRegex = new RegExp(`^https?://(www.)?stanford-ackel.com`)
    // Fix internal
    if (
      domainRegex.test(href) ||
      (href.indexOf('/') === 0 && href.indexOf('//') !== 0)
    ) {
      // Rewrite internal links that point to domain to absolute links to properly support linking on development/staging
      if (domainRegex.test(href)) {
        const parsedURL = new URL(href)
        cleanHref = parsedURL.pathname
      }

      // Add trailing slash
      if (!/\/$/.test(cleanHref)) {
        cleanHref = `${cleanHref}/`
      }

      // Fix locale prefixes. Ensures the right locale is used (or none on default locale). Should only be triggered for links within MDX
      const isDefaultLocale = locale === defaultLocale
      if (enforceLocale) {
        const pathSegments = cleanHref.split('/').filter((v) => v.length)
        if (isDefaultLocale) {
          // should not start with a locale prefix on default
          if (langs.includes(pathSegments[0])) {
            pathSegments.shift()
          }
        } else if (!langs.includes(pathSegments[0])) {
          pathSegments.unshift(locale)
        } else if (pathSegments[0] !== locale) {
          pathSegments[0] = locale
        }

        cleanHref = `/${pathSegments.join(`/`)}/`
      }
    }

    return (
      <a
        className={className}
        href={cleanHref}
        title={formattedTitle}
        {...linkProps}
      >
        {children || formattedTitle}
      </a>
    )
  }

  // Locate fitting page when (page) id is given
  const pages = result.allSitePage.nodes

  if (!pages) {
    // @todo throw errors here
    return null
  }

  const pageMap = generatePageMap({ pages, activePageId: id })

  const page = getPageWithFallback({
    pageMap,
    locale: locale || defaultLocale,
    defaultLocale,
  })

  if (!page) {
    console.warn(
      `Unable to find page with id ${id} and locale ${locale} including fallbacks`,
      pageMap
    )
    // @todo throw errors here
    return null
  }

  const { path, title: pageTitle } = page

  if (!path) {
    console.error('Found page does not have any path to link to', page)
    // @todo throw errors here
    return null
  }

  // Extend path by hash if given
  const dynamicTo = [path, hash ? `#${hash}` : null].filter(Boolean).join('')

  return (
    <GatsbyLink
      className={className}
      activeClassName="active"
      to={dynamicTo}
      title={formattedTitle}
      {...linkProps}
    >
      {children || formattedTitle || formatSupportLineBreaks(pageTitle, false)}
    </GatsbyLink>
  )
}
