exports.components = {
  "component---src-pages-contentful-ui-extensions-schools-js": () => import("./../../../src/pages/contentful/ui-extensions/schools.js" /* webpackChunkName: "component---src-pages-contentful-ui-extensions-schools-js" */),
  "component---src-pages-docs-components-js": () => import("./../../../src/pages/docs/components.js" /* webpackChunkName: "component---src-pages-docs-components-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-preview-js": () => import("./../../../src/pages/docs/preview.js" /* webpackChunkName: "component---src-pages-docs-preview-js" */),
  "component---src-pages-en-sitemap-js": () => import("./../../../src/pages/en/sitemap.js" /* webpackChunkName: "component---src-pages-en-sitemap-js" */),
  "component---src-pages-forms-feedback-boarding-schools-tsx": () => import("./../../../src/pages/forms/feedback-boarding-schools.tsx" /* webpackChunkName: "component---src-pages-forms-feedback-boarding-schools-tsx" */),
  "component---src-pages-forms-feedback-summer-schools-tsx": () => import("./../../../src/pages/forms/feedback-summer-schools.tsx" /* webpackChunkName: "component---src-pages-forms-feedback-summer-schools-tsx" */),
  "component---src-pages-forms-feedback-travel-plans-2025-tsx": () => import("./../../../src/pages/forms/feedback-travel-plans-2025.tsx" /* webpackChunkName: "component---src-pages-forms-feedback-travel-plans-2025-tsx" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-list-js": () => import("./../../../src/templates/blog-post-list.js" /* webpackChunkName: "component---src-templates-blog-post-list-js" */),
  "component---src-templates-boarding-school-js": () => import("./../../../src/templates/boarding-school.js" /* webpackChunkName: "component---src-templates-boarding-school-js" */),
  "component---src-templates-boarding-school-listing-js": () => import("./../../../src/templates/boarding-school-listing.js" /* webpackChunkName: "component---src-templates-boarding-school-listing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-summer-school-book-now-js": () => import("./../../../src/templates/summer-school-book-now.js" /* webpackChunkName: "component---src-templates-summer-school-book-now-js" */),
  "component---src-templates-summer-school-js": () => import("./../../../src/templates/summer-school.js" /* webpackChunkName: "component---src-templates-summer-school-js" */),
  "component---src-templates-summer-school-listing-js": () => import("./../../../src/templates/summer-school-listing.js" /* webpackChunkName: "component---src-templates-summer-school-listing-js" */)
}

