import React, { useMemo } from 'react'

import { useIntersection, useWindowSize } from 'react-use'

import DefaultLoading from './loading'
import useIsClient from '../../hooks/isClient'

/**
 * Ensure children are not rendered till the user scrolls close by
 */
export default function LazyComponent({
  children,
  Loading = DefaultLoading,
  forceRendering = false,
}: {
  /** The children that should not be rendered till the user scrolls close */
  children: React.ReactElement
  /** Overwrite the default loading component */
  Loading?: React.FC
  /** Do not listen for scroll position and force rendering. Useful for components that will get rendered above the fold. */
  forceRendering?: boolean
}) {
  const { isClient } = useIsClient()
  const { height } = useWindowSize()
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: `${height}px 0px ${height}px 0px`,
    threshold: 1,
  })

  const shouldRender = useMemo(
    () => intersection && intersection.intersectionRatio,
    [intersection]
  )

  if (forceRendering) {
    return children
  }

  return (
    <div ref={intersectionRef}>
      {isClient && shouldRender ? children : <Loading />}
    </div>
  )
}
