import React, { useContext } from 'react'
import clsx from 'clsx/lite'

import IconsContext from '../contexts/icons'

import './icon.css'

interface IconProps {
  /** Name of the icon to render. The available icons can be found in the theme documentation for icons */
  icon: string
  /** Color of the icon. Will use current text color by default. */
  color?: string
  /** Adjust the vertical align of the icon. Useful values: top, bottom, middle, baseline, text-top, text-bottom */
  verticalAlign?: string
  svgProps?: React.SVGProps<SVGSVGElement>
  display?: 'block' | 'inline-block'
  className?: string
}


/**
 * Renders an icon. Icons are registered via IconsContext in your gatsby-browser.js
 *
 * @example
 * <Icon icon="star" />
 * <Icon icon="instagram" />
 * <Icon icon="facebook" color="#4267B2" />
 * <Icon icon="youtube" color="red" />
 */
const Icon = ({
  icon,
  svgProps,
  verticalAlign = 'middle',
  color,
  className,
  display = 'inline-block',
  ...props
}: IconProps) => {
  const icons = useContext(IconsContext)
  const IconData = icons.get(icon)
  if (!IconData) {
    return null
    throw new Error(`Unable to locate icon "${icon}"`)
  }
  const ActualIcon = IconData.icon
  return (
    <span
      className={clsx(
        `icon-wrapper`,
        color && `text-${color}`,
        className,
        display === 'block' && 'block'
      )}
      {...props}
      style={{
        verticalAlign,
        transform: IconData.scale && `scale(${IconData.scale})`,
      }}
    >
      <ActualIcon {...svgProps} />
    </span>
  )
}

export default Icon
