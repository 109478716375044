import React, { useContext } from 'react'
import { Link } from 'gatsby'

import MdxSuiteContext from '../../contexts/mdx-suite'
import Icon from '../icon'

import './language-switch.css'

export default function LanguageSwitch(props) {
  const {
    pageContext: { locale: activeLocale, translationPaths },
  } = useContext(MdxSuiteContext)

  if (!translationPaths) {
    return null
  }
  return (
    <ul {...props} className="language-switch">
      {translationPaths.map(([locale, path]) => (
        <li key={locale} hidden={locale === activeLocale}>
          {path && (
            <Link to={path} title={void `languageSwitch-${locale}`}>
              <Icon
                className="icon"
                icon={`flag-${locale}`}
                verticalAlign="middle"
              />
            </Link>
          )}
        </li>
      ))}
    </ul>
  )
}
