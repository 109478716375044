import React from 'react'
import clsx from 'clsx/lite'

import Image from '../../image'

import convertToFlexAlignment from '../../../helpers/styling/convert-to-flex-alignment'

import './section.css'

interface SectionProps {
  children: React.ReactElement
  /** Id the background image */
  backgroundImageId?: string
  /** Set the opacity for the background image */
  backgroundImageOpacity?: string | number
  /** Set the opacity of the background media overlay */
  overlayOpacity?: string | number
  /** Set the color for the background media overlay */
  overlayColor?: string
  /** Optional slight text shadow to increase readability when using background images */
  textShadow?: boolean
  /** Overwrite default vertical margin. See <Link to="/docs/style-guide/#sizes">theme documentation for available sizes</Link> */
  gap?: string
  /** Set the minimum size for the section. Usually used with `100vh` to achieve full screen sizes. See <Link to="/docs/style-guide/#sizes">theme documentation for available sizes</Link> */
  minHeight?: string
  /** Vertical alignment if the available space exceeds the content height */
  verticalAlign?: 'start' | 'center' | 'end'
  /** Horizontal alignment. Will detach the content from the content column. */
  horizontalAlign?: 'start' | 'center' | 'end'
  /** Force background media to be rendered. Set this to true when a section is likely to be rendered above the fold. */
  backgroundForceRendering?: boolean
}

/**
 * Sections are content seperators and serve multiple purposes:
 *
 * * Ensures the given content is rendered within the content column
 * * Adds a gap between multiple sections
 * * They can be colored and supports background images
 *
 * @example <caption>Default</caption>
 * <Section>
 *
 * # Doggy
 *
 * On a plain section, without any styling
 *
 * ![picture of a dog](https://source.unsplash.com/400x300/weekly?dog)
 *
 * </Section>
 *
 * @example <caption>Background image</caption>
 * <Section backgroundImageId="randomPictureId">
 *
 * # Ghosts
 *
 * On a section with a background image
 *
 * <Columns>
 * <Image src="https://source.unsplash.com/400x300/weekly?ghost" alt="Picture of a ghost" />
 * <Image src="https://source.unsplash.com/400x300/weekly?ghost" alt="Picture of a ghost" />
 * </Columns>
 * </Section>
 */

export default function Section({
  children,
  backgroundImageId,
  backgroundImageOpacity = '1',
  textShadow = false,
  gap = 'section-gap',
  minHeight,
  verticalAlign = 'center',
  horizontalAlign,
  overlayOpacity = 0,
  overlayColor = 'black',
  backgroundForceRendering = false,
  ...props
}: SectionProps) {
  const colorSet = backgroundImageId ? 'background-image' : undefined
  const hasBackgroundMedia = !!backgroundImageId
  return (
    <section
      className={clsx(
        'section',
        textShadow && 'text-shadow',
        colorSet && `bg-${colorSet} text-${colorSet}`
      )}
      style={{ minHeight }}
      {...props}
    >
      {hasBackgroundMedia && (
        <div className="absolute z-0 inset-0 w-full h-full overflow-hidden">
          {parseFloat(overlayOpacity + '') > 0 && (
            <div
              className={clsx('absolute inset-0 z-2', `bg-[${overlayColor}]`)}
              style={{
                opacity: overlayOpacity,
              }}
            />
          )}
          {backgroundImageId && (
            <Image
              className={clsx('absolute inset-0 block w-full h-full')}
              style={{ opacity: backgroundImageOpacity }}
              contextKey="screen"
              id={backgroundImageId}
              fit="cover"
            />
          )}
        </div>
      )}
      {children && (
        <div
          className={clsx(
            'section-content-wrapper',
            (minHeight || horizontalAlign) && 'has-height'
          )}
          style={{
            minHeight: minHeight,
            justifyContent: convertToFlexAlignment(verticalAlign),
            alignItems: convertToFlexAlignment(horizontalAlign),
          }}
        >
          <div
            className={clsx(
              horizontalAlign
                ? 'px-content-column-padding'
                : 'center-to-content-column',
              gap ? `py-${gap}` : `py-section-gap`
            )}
          >
            {children}
          </div>
        </div>
      )}
    </section>
  )
}
