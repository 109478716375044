import { createContext } from 'react'

const MdxSuiteContext = createContext<{
  themeConfig: {
    [key: string]: unknown
  }
  pageContext?: {
    locale: string
    pageType: string
    translationPaths: [string, string][]
    [key: string]: unknown
  }
  data: {
    [key: string]: Queries.MdxSuiteContentfulAssetFragment[]
  }
}>({
  // Theme configuration. Automatically set
  themeConfig: {},
  // MDX component rendering data. You may fill these with your processed asset data.
  data: {},
})

export default MdxSuiteContext
