import React, { useMemo, useRef, useState } from 'react'
import { usePopper } from 'react-popper'

import './popper.css'

export const Popper = ({
  referenceElement,
  options,
  children,
  offset,
}: {
  referenceElement: any
  options: any
  children: React.ReactElement
  offset: any
}) => {
  const divRef = useRef(null)
  const [arrowElement, setArrowElement] = useState(null)
  const processedOptions = useMemo(
    () => ({
      ...options,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowElement,
          },
        },
        {
          name: 'offset',
          options: {
            offset: offset,
          },
        },
        {
          name: 'preventOverflow',
        },
      ],
    }),
    [options, arrowElement, offset]
  )
  const { styles, attributes } = usePopper(
    referenceElement,
    divRef.current,
    processedOptions
  )

  return (
    <div
      className="shadow-xl"
      ref={divRef}
      style={styles.popper}
      {...attributes.popper}
    >
      <div className="popper-frame">{children}</div>
      <div
        className="popper-arrow-frame"
        ref={setArrowElement}
        style={styles.arrow}
      >
        <div className="popper-arrow" />
      </div>
    </div>
  )
}
