import React from 'react'

import './loading.css'

const LoadingPlaceholder: React.FC<{
  labelText?: string
}> = ({ labelText = 'Loading...' }) => {
  return (
    <div className="loading-wrapper">
      <div className="wave-wrapper">
        <div className="wave" />
        <div className="wave" />
        <div className="wave" />
        <div className="wave" />
        <div className="wave" />
      </div>
      <div className="mt-4">{labelText}</div>
    </div>
  )
}

export default LoadingPlaceholder
